<template>
  <el-container v-if="info.id">
    <el-main class="left">
      <div class="order-info">
        <div class="title">订单信息</div>
        <div class="content">
          <ul class="order-num">
            <li>
              订单编号：
              <span>{{ info.order_no }}</span>
            </li>
            <li>
              下单时间：
              <span>{{ getDateformat(info.create_time) }}</span>
            </li>
            <li>
              订单来源：
              <span>{{ info.from == 1 ? '微信' : info.from == 2 ? '支付宝' : '快应用' }}</span>
            </li>
          </ul>
          <!-- 显示订单进度 -->
          <div class="handle-progress">
            <div class="progress" v-for="(item, index) in info.stateArr" :key="item.status">
              <div class="item">
                <p class="handle-text" :style="{ color: item.time ? '#409eff' : '#969699' }">{{ item.name }}</p>
                <div class="numsBox">
                  <div class="line" :style="{ background: index === 0 ? '#fff' : '#f0f0f5' }"></div>
                  <div class="num" :style="{ background: item.time ? '#409eff' : '#969699' }">{{ index + 1 }}</div>
                  <div class="line" :style="{ background: index === info.stateArr.length - 1 ? '#fff' : '#f0f0f5' }"></div>
                </div>
                <p class="handle-time">{{ item.time ? getDateformat(item.time) : '--' }}</p>
              </div>
              <div class="bigLine" v-if="index !== info.stateArr.length - 1"></div>
            </div>
          </div>
          <!-- 显示订单状态 -->
          <div class="handle-status">
            <div class="status">订单状态： {{ info.status == -10 ? '已关闭' : info.status == 10 ? '待支付' : info.status == 20 ? '待发货' : info.status == 30 ? '待收货' : '已完成' }}</div>
            <div class="btn" v-if="info.status == 30">
              <el-button type="primary" @click="confirmReceipt">确认收货</el-button>
            </div>
            <div class="btn" v-if="info.status == 20">
              <el-button type="primary" @click="(showOrderSend = !0), (orderSendType = 1)">发货</el-button>
            </div>
            <!-- <div class="order-actions" v-if="info.status > 0">
              <el-button type="text" @click="showMessage = !0">备注</el-button>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 显示买家信息 -->
      <div class="buyers-info">
        <div class="title">买家信息</div>
        <div class="content">
          <!-- <div>
            <p>
              买家：
              <span>{{ info.consignee_name || '--' }}</span>
            </p>
            <p>
              买家备注：
              <span>{{ info.user_message || '--' }}</span>
            </p>
            <p>商家备注：{{ info.system_message || '--' }}</p>
          </div> -->
          <div>
            <p>
              收件人：
              <span>{{ info.consignee_name }}</span>
            </p>
            <p>
              收件人电话：
              <span>{{ info.consignee_phone }}</span>
            </p>
            <p>
              收件人地址：
              <span>{{ info.consignee_address }}</span>
            </p>
          </div>
          <div>
            <p>
              买家备注：
              <span>{{ info.user_message || '--' }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 显示商品信息 -->
      <div class="goods-info">
        <div class="title">商品信息</div>
        <div class="content">
          <el-table :data="info.goods" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column prop="goods_img" label="商品信息" align="center" width="300px">
              <template v-slot="{ row }">
                <div class="goods-title">
                  <el-image :src="row.goods_img"></el-image>
                  <p>
                    {{ row.goods_title }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="兑换积分" align="center">
              <template v-slot="{ row }">{{ row.integral_amount / row.goods_sum }}积分</template>
            </el-table-column>
            <el-table-column label="支付金额" align="center">
              <template v-slot="{ row }">
                <div v-if="Number(row.price)">￥{{ row.price / row.goods_sum }}</div>
                <div v-if="Number(row.freight)">({{ row.freight }}元运费)</div>
              </template>
            </el-table-column>
            <el-table-column prop="goods_sum" label="商品数量" align="center"></el-table-column>
            <el-table-column label="实付" align="center">
              <template v-slot="{ row }">￥{{ row.amount }}</template>
            </el-table-column>
          </el-table>
          <!-- <div class="price">
            <p>
              <span>商品总价：</span>
              <span>￥{{ info.goods_amount }}</span>
            </p>
            <p>
              <span>运费：</span>
              <span>￥{{ info.freight_fee }}</span>
            </p>
            <p>
              <span>优惠券立减：</span>
              <span>￥{{ info.reduction_amount }}</span>
            </p>
            <p>
              <span>{{ info.preference_type == 'distribution' ? '等级优惠：' : '会员立减：' }}</span>
              <span>￥{{ info.discount_reduction_amount }}</span>
            </p>
            <p>
              <span>会员余额立减：</span>
              <span>￥{{ info.pd_amount }}</span>
            </p>
          </div>
          <div class="total-price">
            <span>实付：</span>
            <span>￥{{ Number(info.revise_amount) > 0 ? Number(info.revise_amount) : Number(info.amount) }}</span>
          </div> -->
        </div>
      </div>
    </el-main>
    <!-- 显示物流信息 -->
    <el-main class="logistics-address">
      <div class="title">
        物流信息
        <el-button v-if="info.status == 30" @click="(showOrderSend = !0), (orderSendType = 0)" type="text">修改</el-button>
      </div>
      <div class="sn" v-if="company_name">{{ company_name }}：{{ tracking_no1 }}</div>
      <template v-if="logisticsInfo">
        <el-timeline :reverse="reverse">
          <el-timeline-item v-for="(item, index) in logisticsInfo.data" :key="index" :timestamp="item.time">
            {{ item.context }}
          </el-timeline-item>
        </el-timeline>
      </template>
      <template v-else>
        <No />
      </template>
    </el-main>
    <!-- 备注弹框 -->
    <!-- <el-dialog title="备注" :visible.sync="showMessage" width="35%">
      <el-form v-if="info.id" label-width="85px">
        <el-form-item label="订单编号：">{{ info.order_no }}</el-form-item>
        <el-form-item label="总价：">{{ info.amount }}</el-form-item>
        <el-form-item label="收货人：">{{ info.consignee_name }}</el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="info.system_message" type="textarea" :rows="8" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showMessage = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmSetSystemMessage">确 定</el-button>
      </span>
    </el-dialog> -->
    <!-- 发货-修改物流弹框 -->
    <el-dialog :title="this.orderSendType ? '发货' : '修改物流'" :visible.sync="showOrderSend" width="600px">
      <el-form label-width="90px" v-if="info.id">
        <el-form-item label="收货人：">{{ info.consignee_name }}</el-form-item>
        <el-form-item label="收货地址：">{{ info.consignee_address }}</el-form-item>
        <el-form-item label="快递公司：">
          <el-select v-model="express_id" filterable placeholder="请选择">
            <el-option v-for="item in logisticsList" :key="item.id" :label="item.company_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号：">
          <el-input v-model="tracking_no"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showOrderSend = !1">取 消</el-button>
        <el-button type="primary" @click="confirmSend">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      getDateformat,
      id: '',
      info: {},
      goods_info: [],
      logisticsInfo: '',
      showMessage: !1,
      showOrderSend: !1,
      express_id: '', //快递公司
      tracking_no: '', //快递单号
      company_name: '',
      tracking_no1: '',
      orderSendType: 1,
    };
  },
  computed: {
    ...mapState({
      logisticsList: state => state.order.logisticsList, //物流公司列表
    }),
  },
  watch: {
    showOrderSend(val) {
      if (val && !this.logisticsList.length) this.$store.dispatch('order/getLogistics', { rows: 10000 });
    },
  },
  created() {
    let utils = this.$store.state.utils;
    utils.page = Number(this.$route.query.page);
    utils.rows = Number(this.$route.query.rows);
    utils.is_record = !0;
    this.id = this.$route.query.id;
    this.getOrderInfo();
  },
  methods: {
    handleSales(val) {
      this.handle_state = val;
      this.showHandleSales = !0;
    },
    // comfirmSetSystemMessage() {
    //   this.$axios
    //     .post(this.$api.order.setSystemMessage, {
    //       id: this.id,
    //       system_message: this.info.system_message,
    //     })
    //     .then(res => {
    //       if (res.code == 0) {
    //         this.showMessage = !1;
    //         this.$message({
    //           message: '备注成功',
    //           type: 'success',
    //         });
    //       } else {
    //         this.$message.error(res.msg);
    //       }
    //     });
    // },
    //发货
    confirmSend() {
      // let orderInfo = this.info.extend_order_goods;
      // for (let i in orderInfo) {
      //   if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可发货！');
      // }
      if (!this.tracking_no) this.$message.warning('请填写快递单号');
      let data = {
        express_number: this.tracking_no,
        id: this.id,
        express_id: this.express_id,
      };
      if (this.orderSendType) data.status = 30;
      let url = this.orderSendType ? this.$api.serviceProvider.integral.updateStatus : this.$api.serviceProvider.integral.editExpress;
      this.$axios.post(url, data).then(res => {
        if (res.code == 0) {
          this.getOrderInfo();
          this.showOrderSend = !1;
          this.$message.success(this.orderSendType ? '发货成功' : '修改成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //确认收货
    confirmReceipt() {
      this.$confirm(`是否确认强制收货（谨慎使用！）`, '提示', {
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.serviceProvider.integral.updateStatus, { id: this.info.id, status: 40 }).then(res => {
          if (res.code == 0) {
            this.$message({
              message: '订单收货成功',
              type: 'success',
            });
            this.getOrderInfo();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    //查看物流信息
    logisticsQuery() {
      if (this.info.status <= 20) return;
      this.$axios.post(this.$api.serviceProvider.integral.logisticsQuery, { id: this.id }).then(res => {
        if (res.code == 0) {
          this.company_name = res.result.company_name;
          this.logisticsInfo = res.result.info;
          this.tracking_no1 = res.result.info.nu;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取商品信息
    getOrderInfo() {
      this.$axios.post(this.$api.serviceProvider.integral.info, { id: this.id }).then(res => {
        if (res.code == 0) {
          let info = res.result;
          info.from = 1;
          // 处理订单进度显示
          let stateArr = [];
          stateArr.push({ name: '提交时间', time: info.create_time, status: 10 });
          stateArr.push({ name: '在线支付', time: info.pay_time, status: 20 });
          stateArr.push({ name: '商品发货', time: info.delivery_time, status: 30 });
          stateArr.push({ name: '确认收货', time: info.receiving_time, status: 40 });
          stateArr.push({ name: '订单完成', time: info.receiving_time, status: 40 });
          if (info.status == -10) {
            for (let i = 0; i < stateArr.length; i++) {
              if (!stateArr[i].time) {
                stateArr.splice(i, 0, { name: '取消订单', time: info.update_time, status: -10 });
                break;
              }
            }
          }
          info.stateArr = stateArr;
          // 处理商品详情
          let goods = {
            goods_img: info.goods_cover_picture,
            goods_title: info.goods_name,
            integral_amount: info.integral_amount,
            price: info.price,
            freight: info.freight,
            goods_sum: info.goods_sum,
            amount: info.amount,
          };
          res.result.goods = [goods];
          this.info = info;
          this.logisticsQuery();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  font-family: Microsoft YaHei;
  font-size: 14px;
  color: #17171a;
}
.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 20px;
}
.left {
  margin-right: 8px;
  background: #fff;
  .order-info {
    margin-bottom: 30px;
  }
  .order-info .content {
    .order-num {
      display: flex;
      height: 47px;
      background: #f0f0f5;
      align-items: center;
      padding-left: 24px;
      li {
        font-size: 14px;
        margin-right: 40px;
        span {
          font-weight: 400;
          color: #17171a;
        }
      }
    }
    .handle-progress {
      display: flex;
      justify-content: center;
      height: 190px;
      align-items: center;
      .progress {
        display: flex;
        align-items: center;
        .bigLine {
          height: 4px;
          background: #f0f0f5;
          width: 20px;
        }
      }

      .item {
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .numsBox {
          width: 100%;
          display: flex;
          align-items: center;
          margin: 10px 0;
          .line {
            height: 4px;
            background: #f0f0f5;
            flex: 1;
          }

          .num {
            width: 32px;
            height: 32px;
            flex-shrink: 0;
            line-height: 32px;
            text-align: center;
            background: #c8c8cc;
            border-radius: 50%;
            color: #ffffff;
            font-size: 14px;
            font-weight: bold;
          }
        }

        .handle-text {
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          color: #969699;
          height: 16px;
        }
        .handle-time {
          min-width: 120px;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          color: #969699;
          height: 16px;
        }
      }
    }
    .handle-status {
      padding: 30px 0;
      background: #f0f0f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .status {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #969699;
      }
      .btn {
        margin-top: 18px;
      }
      .order-actions {
        margin-top: 10px;
      }
    }
  }
  .buyers-info {
    margin-bottom: 30px;
    .content {
      margin-top: 24px;
      padding-left: 40px;
      div {
        display: flex;

        span {
          font-weight: 400;
        }
        p {
          width: 240px;
          &:last-child {
            width: auto;
          }
        }
        &:nth-child(1) {
          margin-bottom: 8px;
        }
      }
    }
  }
  .goods-info .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      margin-top: 20px;
      border-bottom: 1px solid #f5f5fa;
      color: #969699;
      span:last-child {
        color: #17171a;
        width: 80px;
        text-align: right;
      }
      p {
        display: flex;
        margin-bottom: 5px;
      }
    }
    .goods-title {
      display: flex;
      justify-content: center;
      align-items: center;
      .el-image {
        width: 68px;
        height: 68px;
        border-radius: 8px;
        margin-right: 8px;
      }
      p {
        flex: 1;
      }
    }
    .total-price {
      font-weight: bold;
      margin-top: 5px;
      display: flex;
      span:last-child {
        text-align: right;
        font-weight: 400;
        color: #fd5e94;
        width: 80px;
      }
    }
  }
}

.logistics-address {
  width: 480px;
  background: #fff;
  overflow: hidden;
  .sn {
    margin-bottom: 30px;
  }
  /deep/ .el-timeline-item__content {
    color: #17171a;
  }
}

.formdata {
  .row {
    display: flex;
    line-height: 36px;
    .row_left {
      width: 160px;
      text-align: right;
      flex-shrink: 0;
    }
    .row_right {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      .el-image {
        height: 76px;
        width: auto;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
}
</style>
